import Alert from "@mui/material/Alert";
import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { PER_PAGE } from "../../../config";
import { usePostsContext } from "../../../context/posts";
import { OfferStatus } from "../../../types/offers";
import { Post, PostStatus } from "../../../types/posts";
import PageCard from "../../components/PageCard";
import SmallButton from "../../components/SmallButton";
import { useUser } from "../../hooks/useUser";
import AwaitingPayment from "./components/AwaitingPayment";
import CryptoAddress from "./components/CryptoAddress";
import PendingTransactions from "./components/PendingTransactions";
import { styles } from "./styles";
import { Box, Tab, Tabs } from "@mui/material";
import Widget from "./components/Widget";
import { TransactionStatus } from "../../../types/transactions";
import { PaymentStatus } from "../../../types/payment";
import { useUsersContext } from "../../../context/users";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={styles.tabPanel.wrapper}
      {...other}
    >
      {value === index && <Box sx={styles.tabPanel.content}>{children}</Box>}
    </div>
  );
}

export default function Dashboard() {
  const {
    state: { user },
  } = useUsersContext();
  const { state, refetchPosts, refetchUserTransactions, refetchOffersSent } = usePostsContext();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (user)
      refetchPosts({
        user: user?.sub,
        page: 0,
        limit: PER_PAGE * 2,
      });
  }, [user]);

  const pendingOffers = useMemo(() => {
    let _pendingOffers = 0;
    state.posts.forEach((post) => {
      post.offers.forEach((offer) => {
        if (offer.status === OfferStatus.PENDING) _pendingOffers++;
      });
    });
    return _pendingOffers;
  }, [state.posts]);

  const sellerAwaitingBuyerPayment = useMemo(() => {
    let acceptedOffersAwaitingPayment = 0;
    state.posts.forEach((post) => {
      post.offers.forEach((offer) => {
        if (
          offer.status === OfferStatus.ACCEPTED &&
          post.status === PostStatus.PENDING &&
          !post.transactions.find((transaction) => transaction.status === TransactionStatus.PENDING)
        ) {
          acceptedOffersAwaitingPayment++;
        }
      });
    });
    return acceptedOffersAwaitingPayment;
  }, [state.posts, user]);

  const pendingPayments: Post[] = useMemo(() => {
    return state.offersSent.filter((post) => {
      const paymentAlreadyMade = post.payments.find((payment) => payment.status === PaymentStatus.PAID || payment.status === PaymentStatus.ESCROW);
      const acceptedOffer = post.offers.find((offer) => offer.status === OfferStatus.ACCEPTED && offer.author._id === user?.sub);
      return !paymentAlreadyMade && acceptedOffer;
    });
  }, [state.offersSent, user]);

  const pendingTransactions: Post[] = useMemo(() => {
    return state.transactions.filter((post) => post.transactions.find((transaction) => transaction.status === TransactionStatus.PENDING));
  }, [state.transactions]);

  useEffect(() => {
    if (user) refetchUserTransactions();
  }, [user]);

  useEffect(() => {
    if (user) refetchOffersSent();
  }, [user]);

  return (
    <PageCard>
      <div style={styles.container}>
        {sellerAwaitingBuyerPayment !== 0 ? (
          <div style={styles.alert}>
            <Alert variant="filled" severity="success">
              You have {sellerAwaitingBuyerPayment} post{sellerAwaitingBuyerPayment > 1 ? "s" : ""} awaiting payment
            </Alert>
          </div>
        ) : (
          <></>
        )}
        <span style={styles.buttons}>
          <SmallButton variant="secondary" text="Posts" onClick={() => navigate("/postings")} />
        </span>
        <span style={styles.buttons}>
          <SmallButton
            variant="secondary"
            text="Offers"
            onClick={() => navigate("/offers")}
            icon={pendingOffers > 0 ? <span style={styles.pending}>{pendingOffers}</span> : <></>}
          />
        </span>
        <span style={styles.buttons}>
          <SmallButton variant="secondary" text="Transactions" onClick={() => navigate("/transactions")} />
        </span>
        <Box sx={styles.tabsContainer}>
          <Tabs orientation="vertical" variant="scrollable" value={tabValue} onChange={handleTabChange} sx={styles.tabsList}>
            <Tab label="Bitcoin Address" sx={styles.tab} />
            <Tab
              label={
                <span style={{ display: "flex", alignItems: "center" }}>
                  <span style={styles.tabTitle}>Awaiting Payments</span>
                  {pendingPayments.length > 0 && <span style={styles.pending}>{pendingPayments.length}</span>}
                </span>
              }
              sx={styles.tab}
            />
            <Tab
              label={
                <span style={{ display: "flex", alignItems: "center" }}>
                  <span style={styles.tabTitle}>Pending Transactions</span>
                  {pendingTransactions.length > 0 && <span style={styles.pending}>{pendingTransactions.length}</span>}
                </span>
              }
              sx={styles.tab}
            />
          </Tabs>

          <Box sx={styles.tabContent}>
            <TabPanel value={tabValue} index={0}>
              <Widget title="Bitcoin Address">
                <CryptoAddress />
              </Widget>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Widget title="Awaiting Payments">
                <AwaitingPayment pendingPayments={pendingPayments} />
              </Widget>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <Widget title="Pending Transactions">
                <PendingTransactions pendingTransactions={pendingTransactions} />
              </Widget>
            </TabPanel>
          </Box>
        </Box>
      </div>
    </PageCard>
  );
}
