import jwtDecode from "jwt-decode";
import { useMemo } from "react";
import { ACCESS_TOKEN_ACCESS_KEY } from "../../config";
import { TokenUser } from "../../types/users";
import { LocalStorage } from "../../utils/local-storage";
import { useLoginUser } from "../Users/hooks/useLoginUser";

const SEVENTY_TW0_HOURS = 1000 * 60 * 60 * 72;

export const useUser = () => {
  const { doSignOut } = useLoginUser();

  const user = useMemo(() => {
    const accessToken = LocalStorage.get(ACCESS_TOKEN_ACCESS_KEY) || null;
    const tokenUser: TokenUser | null = accessToken ? jwtDecode(accessToken) : null;
    if (tokenUser) {
      const isTokenExpired = Date.now() - tokenUser.date > SEVENTY_TW0_HOURS;
      if (isTokenExpired) doSignOut();
      else return tokenUser;
    }

    return null;
  }, [LocalStorage.get(ACCESS_TOKEN_ACCESS_KEY)]);

  return {
    user,
  };
};
