import { useEffect, useState } from "react";
import { PostQueryParams, PostStatus } from "../../../types/posts";
import CategoryBar from "../../Categories/CategoryBar";
import Banner from "../../Common/Banner";
import PaginatedPosts from "../components/PaginatedPosts";
import { styles } from "./style";
import { Link } from "react-router-dom";
import { useRootContext } from "../../../context/root";

const NUM_POSTS = 4;

const Feature = () => {
  const {
    state: { location },
  } = useRootContext();

  const [filter, setFilter] = useState<PostQueryParams>({
    page: 0,
    limit: NUM_POSTS, // Feature page should just show 4 posts
    status: PostStatus.OPEN,
    location,
  });

  useEffect(() => {
    setFilter((prev) => ({ ...prev, location }));
  }, [location]);
  return (
    <>
      <CategoryBar />
      <Banner
        title={
          <span>
            <Link style={styles.browseLink} to={"/browse"}>
              Browse
            </Link>
            Items For Sale, List Your Items, Earn Crypto.
          </span>
        }
      />
      {location && (
        <div style={styles.posts}>
          <PaginatedPosts filter={filter} setFilter={setFilter} />
        </div>
      )}
    </>
  );
};
export default Feature;
