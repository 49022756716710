import { useState } from "react";
import { generateNewAddress } from "../../../api/payments";
import useToggleToast from "../../components/hooks/useToggleToast";
import { AxiosError } from "axios";
import { ToastSeverity } from "../../components/types/toast-severity";

export const useNewAddress = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [address, setAddress] = useState<string>();
  const { toggle } = useToggleToast();

  const doGenerateAddress = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      const result = await generateNewAddress();
      setAddress(result);
    } catch (err) {
      toggle((err as AxiosError<any>).response?.data.message, ToastSeverity.ERROR);
      setError(err as Error);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error,
    isLoading,
    address,
    generateAddress: doGenerateAddress,
  };
};
