import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreatePost } from "../../../types/posts";
import { useCategories } from "../../Categories/hooks/useCategories";
import useToggleToast from "../../components/hooks/useToggleToast";
import { ToastSeverity } from "../../components/types/toast-severity";
import { useCreatePost } from "../hooks/usePostCreate";
import { styles } from "./style";
import { useFetchLocations } from "../../Locations/hooks/useFetchLocations";
import { BETA_COUNTRY, CityProvinceMap, CONDITIONS, INITIAL_POST, IMAGE_LIMIT, TITLE_CHAR_LIMIT, DESC_CHAR_LIMIT, STEPS } from "./constants";

export default function PostCreate() {
  const [activeStep, setActiveStep] = useState(0);
  const [post, setPost] = useState<CreatePost>(INITIAL_POST);
  // @ts-ignore
  const [images, setImages] = useState<FileList>([]);

  const { isLoading, doCreatePost } = useCreatePost();
  const { categories } = useCategories();

  const navigate = useNavigate();
  const { toggle } = useToggleToast();

  const { locations } = useFetchLocations();

  const navigateOnSubmit = () => navigate("/browse");

  const handleSetImages = (i: FileList) => {
    if (!i) return;
    if (i.length > IMAGE_LIMIT) toggle(`You may only upload a maximum of ${IMAGE_LIMIT} images`, ToastSeverity.WARNING);

    setImages(i);
  };

  const handleOnSubmit = () => {
    if (!post.title || !post.description || !post.condition || !post.price || !post.address.city || !post.address.postalCode)
      return toggle("Please complete all required fields", ToastSeverity.ERROR);
    if (images.length > IMAGE_LIMIT) return toggle(`You cannot select more than ${IMAGE_LIMIT} images`, ToastSeverity.ERROR);
    const formData = new FormData();

    for (let i = 0; i < images.length; i++) {
      formData.append("files", images[i]);
    }

    formData.append("title", String(post.title));
    formData.append("description", String(post.description));
    formData.append("condition", String(post.condition));
    formData.append("price", String(post.price));
    formData.append("category", String(post.category));
    formData.append("street", post.address.street);
    formData.append("state", post.address.state);
    formData.append("city", post.address.city);
    formData.append("postalCode", post.address.postalCode);
    formData.append("country", BETA_COUNTRY);

    doCreatePost(formData, navigateOnSubmit);
  };

  const isTextLimitsExceeded = () => {
    return post.title.length > TITLE_CHAR_LIMIT || post.description.length > DESC_CHAR_LIMIT;
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Stack spacing={2}>
            <Box>
              <TextField
                size="small"
                fullWidth
                label="Title"
                variant="outlined"
                value={post.title}
                inputProps={{ maxLength: TITLE_CHAR_LIMIT }}
                onChange={(e) => setPost({ ...post, title: e.target.value })}
              />
              <Typography variant="caption" color="text.secondary" sx={styles.characterCount}>
                {post.title.length}/{TITLE_CHAR_LIMIT}
              </Typography>
            </Box>
            <Box>
              <TextField
                size="small"
                fullWidth
                label="Description"
                variant="outlined"
                multiline
                rows={8}
                value={post.description}
                inputProps={{ maxLength: DESC_CHAR_LIMIT }}
                onChange={(e) => setPost({ ...post, description: e.target.value })}
              />
              <Typography variant="caption" color="text.secondary" sx={styles.characterCount}>
                {post.description.length}/{DESC_CHAR_LIMIT}
              </Typography>
            </Box>
          </Stack>
        );
      case 1:
        return (
          <Stack spacing={2}>
            <Box sx={styles.flexBox}>
              <FormControl fullWidth>
                <InputLabel size="small">Condition</InputLabel>
                <Select
                  size="small"
                  value={post.condition}
                  label="Condition"
                  onChange={(e) => setPost({ ...post, condition: Number(e.target.value) })}
                >
                  {CONDITIONS.map((val) => (
                    <MenuItem key={val} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                size="small"
                fullWidth
                label="Price"
                type="number"
                value={post.price}
                onChange={(e) => setPost({ ...post, price: parseInt(e.target.value) })}
              />
            </Box>
            <FormControl fullWidth>
              <InputLabel size="small">Category</InputLabel>
              <Select
                size="small"
                value={post.category}
                label="Category"
                onChange={(e) => setPost({ ...post, category: [e.target.value] as string[] })}
              >
                {categories.map((category) => (
                  <MenuItem key={category.title} value={category.title.toLowerCase()}>
                    {category.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        );
      case 2:
        return (
          <Paper variant="outlined" sx={styles.imageUploadPaper}>
            <input
              type="file"
              multiple
              accept="image/png, image/jpeg, image/jpg"
              onChange={(e) => handleSetImages(e.target.files!)}
              style={styles.hiddenInput}
              id="image-upload"
            />
            <label htmlFor="image-upload">
              <Button variant="outlined" component="span">
                Upload Images
              </Button>
            </label>
            <Typography variant="caption" display="block" sx={styles.uploadHelperText}>
              Upload up to {IMAGE_LIMIT} images (jpeg, jpg, png)
            </Typography>
            {images.length > 0 && (
              <Typography variant="body2" sx={styles.selectedFilesText}>
                {images.length} file(s) selected
              </Typography>
            )}
          </Paper>
        );
      case 3:
        return (
          <Stack spacing={2}>
            <FormControl fullWidth>
              <InputLabel size="small">City</InputLabel>
              <Select
                size="small"
                value={post.address.city}
                label="City"
                onChange={(e) =>
                  setPost({
                    ...post,
                    address: {
                      ...post.address,
                      state: CityProvinceMap[e.target.value.toLowerCase()],
                      city: e.target.value,
                    },
                  })
                }
              >
                {locations.map((val) => (
                  <MenuItem key={val.city} value={val.city}>
                    {val.city}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField size="small" fullWidth label="Province" value={post.address.state} disabled />
            <TextField
              size="small"
              fullWidth
              label="Postal Code"
              value={post.address.postalCode}
              onChange={(e) =>
                setPost({
                  ...post,
                  address: { ...post.address, postalCode: e.target.value },
                })
              }
            />
            <TextField size="small" fullWidth label="Country" value={BETA_COUNTRY} disabled />
          </Stack>
        );
    }
  };

  const handleNext = () => {
    if (activeStep === STEPS.length - 1) {
      handleOnSubmit();
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <Container maxWidth="md" sx={styles.container}>
      <Paper sx={styles.paper}>
        <Typography variant="h5" gutterBottom>
          Create New Post
        </Typography>

        <Stepper activeStep={activeStep} sx={styles.stepper}>
          {STEPS.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box sx={styles.contentBox}>{renderStepContent(activeStep)}</Box>

        <Box sx={styles.navigationBox}>
          <Button size="small" disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          <Box>
            {isLoading ? (
              <CircularProgress size={20} />
            ) : (
              <Button size="small" variant="contained" onClick={handleNext} disabled={activeStep === 0 && isTextLimitsExceeded()}>
                {activeStep === STEPS.length - 1 ? "Create Post" : "Next"}
              </Button>
            )}
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}
