import { CheckCircleOutline } from "@mui/icons-material";
import { Button, CircularProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import SmallButton from "../../../../components/SmallButton";
import { useBalance } from "../../../../Payments/hooks/useBalance";
import { useWithdrawFunds } from "../../../../Payments/hooks/useWithdrawFunds";
import { styles } from "./styles";
import Block from "../Block";
import { isValidBitcoinAddress } from "../../../../../utils/bitcoinValidation";
import useToggleToast from "../../../../components/hooks/useToggleToast";
import { ToastSeverity } from "../../../../components/types/toast-severity";

export default function WithdrawFunds() {
  const [confirm, setConfirm] = useState<boolean>(false);
  const [address, setAddress] = useState<string>("");
  const { balance, getBalance } = useBalance();
  const { doWithdrawFunds, isLoading, isSuccess } = useWithdrawFunds();
  const { toggle } = useToggleToast();

  useEffect(() => {
    getBalance();
  }, []);

  const onClick = () => {
    if (address && isValidBitcoinAddress(address)) setConfirm(true);
    else toggle("Invalid Bitcoin address", ToastSeverity.ERROR);
  };

  /**
   * UI
   */
  function renderButtonLogic() {
    if (isLoading) {
      return <CircularProgress />;
    } else {
      return (
        <Button variant="contained" onClick={onClick}>
          Withdraw
        </Button>
      );
    }
  }

  function render() {
    if (isSuccess) {
      return (
        <div style={styles.success}>
          <div style={styles.iconContainer}>
            <CheckCircleOutline style={styles.icon} />
          </div>
          <p style={styles.iconText}>Withdrawal Sent</p>
        </div>
      );
    } else {
      if (confirm) {
        return (
          <div style={styles.confirmationText}>
            <p style={styles.text}>
              Are you sure you want to send your balance to <span style={styles.addressPreview}>{address}</span>?{" "}
              <span style={styles.warn}> This action is irreversible</span>
            </p>
            <div style={styles.confirmButtons}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <SmallButton text="cancel" onClick={() => setConfirm(false)} variant="primary" />
                  <SmallButton text="confirm" onClick={() => doWithdrawFunds(address)} variant="secondary" />
                </>
              )}
            </div>
          </div>
        );
      } else {
        return (
          <>
            <div style={styles.address}>
              <TextField
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                id="outlined-basic"
                label="Target Address"
                variant="outlined"
                size="small"
              />
            </div>
            <div style={styles.button}>{renderButtonLogic()}</div>
          </>
        );
      }
    }
  }

  return (
    <Block title="Withdraw Funds">
      <div style={styles.container}>
        <p style={styles.balance}>
          {balance.btc}(${balance.usd.toFixed(2)} USD)
        </p>
        {render()}
      </div>
    </Block>
  );
}
