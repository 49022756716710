import Card from "./components/PostCard";
import React, { useEffect } from "react";
import { styles } from "./style";
import { CircularProgress } from "@mui/material";
import { PostQueryParams } from "../../../../types/posts";
import { usePosts } from "../../hooks/usePosts";

interface PaginatedPostsProps {
  filter: PostQueryParams;
  setFilter: React.Dispatch<React.SetStateAction<PostQueryParams>>;
}

export default function PaginatedPosts({ filter, setFilter }: PaginatedPostsProps) {
  useEffect(() => {
    setFilter((prev: PostQueryParams) => ({ ...prev, page: 0 }));
  }, []);

  const { posts, isLoading, refetchPosts } = usePosts();

  useEffect(() => {
    if (filter) {
      refetchPosts(filter);
    }
  }, [filter]);

  return (
    <div>
      {isLoading ? (
        <div style={styles.loading}>
          <CircularProgress />
        </div>
      ) : (
        <div style={styles.posts}>
          {posts.map((post) => (
            <Card key={post._id} post={post} />
          ))}
        </div>
      )}
    </div>
  );
}
