import { useMediaQuery } from "@mui/material";
import { FOOTER_HEIGHT, HEADER_HEIGHT, isTablet } from "../../theme";

export function useStyles() {
  const matchTablet = useMediaQuery(isTablet);
  const styles = {
    container: {
      margin: "auto",
      minHeight: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`,
      width: "100%",
    },
    pageContainer: {
      minHeight: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`,
      width: "100%",
    },
  };

  return {
    styles,
    matchTablet,
  };
}
