import { useState } from "react";
import { getSeed } from "../../../api/users";
import useToggleToast from "../../components/hooks/useToggleToast";
import { ToastSeverity } from "../../components/types/toast-severity";
import { NestErrorResponse } from "../../../types/util";

export const useGetSeed = () => {
  const [result, setResult] = useState<{ seed: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { toggle } = useToggleToast();

  const doGetSeed = async () => {
    try {
      if (isLoading) return;

      setIsLoading(true);

      const result = await getSeed();
      setResult(result);
    } catch (err) {
      toggle((err as NestErrorResponse).response?.data.message, ToastSeverity.ERROR);
      setError(err as NestErrorResponse);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
  return {
    error,
    isLoading,
    doGetSeed,
    result,
  };
};
