import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { activate } from "../../../api/users";
import useToggleToast from "../../components/hooks/useToggleToast";
import { ToastSeverity } from "../../components/types/toast-severity";

export const useActivateAccount = (_activationCode: string | null) => {
  const [isSuccess, setIsSuccess] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError | null>(null);
  const { toggle } = useToggleToast();

  const doActivateUser = async () => {
    try {
      if (!_activationCode) throw new Error("No activation code");
      setIsLoading(true);

      const response = await activate(_activationCode);
      setIsSuccess(response.success);
    } catch (err) {
      setError(err as AxiosError);
      console.error(err);
      toggle((err as AxiosError<any>).response?.data.message || "Account not found", ToastSeverity.ERROR);
      setIsSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    doActivateUser();
  }, [_activationCode]);

  return {
    isSuccess,
    isLoading,
    error,
  };
};
