import { CSSProperties } from "react";
import { theme } from "../../../theme";

export const styles = {
  container: {
    width: "100%",
  } as CSSProperties,
  widget: {
    marginTop: 15,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  } as CSSProperties,
  buttons: {
    marginRight: "10px",
  } as CSSProperties,
  pending: {
    backgroundColor: theme.colors.green,
    padding: "2px 6px",
    fontSize: 11,
  },
  alert: {
    marginBottom: "25px",
  },
  tabsContainer: {
    display: "flex",
    height: "400px",
    bgcolor: "background.paper",
    padding: theme.padding.s,
  },
  tabsList: {
    borderRight: 1,
    borderColor: "divider",
    minWidth: "200px",
  },
  tabContent: {
    flexGrow: 1,
  },
  tabPanel: {
    wrapper: {
      width: "100%",
    },
    content: {
      p: 3,
    },
  },
  tab: {
    alignItems: "flex-start",
    textAlign: "left",
    padding: "12px 16px",
    minWidth: "unset",
    color: theme.colors.primary,
    "&.Mui-selected": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      color: theme.colors.secondary,
    },
  },
  tabTitle: {
    marginRight: "5px",
  },
};
