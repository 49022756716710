import { AxiosError } from "axios";
import { useState } from "react";
import useToggleToast from "../../components/hooks/useToggleToast";
import { ToastSeverity } from "../../components/types/toast-severity";
import { validate } from "validate.js";
import { setNewPassword } from "../../../api/users";

export const passwordConstraints = {
  password: {
    length: {
      minimum: 6,
      message: "must be at least 6 characters",
    },
    format: {
      // Combined regex: checks for lowercase, uppercase, number, and symbol
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>]).*$/,
      message: "must contain at least one lowercase letter, one uppercase letter, one number, and one symbol",
    },
  },
};

export const useSetNewPassword = () => {
  const [result, setResult] = useState<{ success: boolean } | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { toggle } = useToggleToast();

  const doSetNewPasswordUser = async (newPassword: string, hash: string | null) => {
    if (!hash) return;
    if (!newPassword) return;
    const isValid = validate({ password: newPassword }, passwordConstraints);
    try {
      if (validate({ password: newPassword }, passwordConstraints)) throw new Error(isValid.password[0]);
      if (isLoading) return;

      setIsLoading(true);

      const result = await setNewPassword({ password: newPassword, hash });
      setResult(result);
    } catch (err) {
      toggle((err as AxiosError<any>).response?.data.message, ToastSeverity.ERROR);
      setError(err as AxiosError);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
  return {
    error,
    isLoading,
    doSetNewPasswordUser,
    result,
  };
};
