import { SxProps, Theme } from "@mui/material";
import { theme } from "../../../../../theme";

export const styles = {
  container: {
    textAlign: "center",
  },
  title: {
    marginBottom: 2,
    textAlign: "center",
    color: theme.colors.primary,
    fontWeight: 600,
    fontSize: "1.25rem",
    fontFamily: "'Roboto', sans-serif",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    p: 2,
  },
  list: {
    width: "100%",
    borderColor: theme.colors.secondary,
  },
  listItem: {
    cursor: "pointer",
    textAlign: "center",
    "&:hover": {
      backgroundColor: `${theme.colors.primary}15`, // Using primary color with 15% opacity
    },
  },
} satisfies Record<string, SxProps<Theme>>;
