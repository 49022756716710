import React from "react";
import { Typography, List, ListItem, ListItemText, CircularProgress, Box } from "@mui/material";
import { useFetchLocations } from "../../../../Locations/hooks/useFetchLocations";
import useToggleModal from "../../../hooks/useToggleModal";
import { LocalStorage } from "../../../../../utils/local-storage";
import { LocalStorageKeys } from "../../../../../types/common";
import { useRootContext } from "../../../../../context/root";
import { ActionTypes } from "../../../../../context/root/actions";
import { styles } from "./styles";

export default function Location() {
  const { locations, isLoading } = useFetchLocations();
  const { toggle } = useToggleModal();
  const { dispatch } = useRootContext();

  const handleClick = (location: string) => {
    LocalStorage.set(LocalStorageKeys.LOCATION, location);
    dispatch({ type: ActionTypes.SET_LOCATION, payload: { location } });
    toggle(null);
  };

  return (
    <Box sx={styles.container}>
      <Typography variant="h6" sx={styles.title}>
        Select a location
      </Typography>
      {isLoading ? (
        <Box sx={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      ) : (
        <List sx={styles.list}>
          {locations.map((location) => (
            <ListItem key={location._id} onClick={() => handleClick(location.city)} sx={styles.listItem}>
              <ListItemText primary={location.city} />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
}
