import { useState, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import { Search, SearchIconWrapper, StyledInputBase, styles as localStyles, styles } from "./styles";
import { theme } from "../../../theme";
import { useAuthToken } from "../../hooks/useAuthToken";
import { Avatar, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/white-transparent.png";
import { useBalance } from "../../Payments/hooks/useBalance";
import DropDownMenu from "./components/DropDownMenu";
import { useUsersContext } from "../../../context/users";
import PostAddIcon from "@mui/icons-material/PostAdd";
import RoomIcon from "@mui/icons-material/Room";
import useToggleModal from "../../components/hooks/useToggleModal";
import { Currency, LocalStorageKeys, ModalTypes } from "../../../types/common";
import { LocalStorage } from "../../../utils/local-storage";
import { useRootContext } from "../../../context/root";
import { formatBTCAmount, formatCurrencyAmount, handleListenEnter, isMobile } from "../../../utils/lib";

export default function Header() {
  const {
    state: { location },
  } = useRootContext();
  const [query, setQuery] = useState<string>("");
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);

  const { access_token } = useAuthToken();
  const { state } = useUsersContext();
  const navigate = useNavigate();
  const { balance, getBalance } = useBalance();
  const { toggle } = useToggleModal();
  const ref = useRef(null);

  const handleChange = (e: any) => {
    setQuery(e.target.value);
  };

  const onSubmit = (e: any) => {
    if (query.length) navigate(`/search?q=${query}`);
  };

  useEffect(() => {
    if (!location && !isMobile()) {
      toggle({ type: ModalTypes.LOCATION, isModalOpen: true });
    }
  }, [location]);

  useEffect(() => {
    if (state.user) {
      getBalance();
    }
  }, [state.user]);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        setShowMenu(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <AppBar
      position="static"
      style={{
        background: theme.colors.primary,
        height: "100%",
      }}
    >
      <Toolbar style={{ height: "100%" }}>
        <Link to="/browse">
          <img src={Logo} alt="React Logo" height={45} width={70} />
        </Link>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
            onChange={handleChange}
            onKeyUp={(e) => handleListenEnter(e, onSubmit)}
          />
        </Search>
        <div
          style={styles.location}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          onClick={() => !isMobile() && toggle({ type: ModalTypes.LOCATION, isModalOpen: true })}
        >
          <RoomIcon
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            style={{ cursor: "pointer", marginLeft: 10, fontSize: 28, color: isHover ? theme.colors.secondary : theme.colors.white }}
          />
          <p style={{ textDecoration: isHover ? "underline" : "", cursor: "pointer" }}>{LocalStorage.get(LocalStorageKeys.LOCATION)}</p>
        </div>
        <div style={localStyles.container}>
          {/* Not Logged in */}
          {!access_token ? (
            <>
              <p style={localStyles.authText} onClick={() => navigate("/login")}>
                Sign In
              </p>
              <span style={localStyles.orText}>or</span>
              <p style={localStyles.authText} onClick={() => navigate("/register")}>
                Register
              </p>
            </>
          ) : (
            <div style={styles.signedIn}>
              {/* Logged in */}
              <span style={styles.balance}>
                <span>{formatBTCAmount(balance.btc)}</span>
                <span>({formatCurrencyAmount(balance.usd, Currency.USD)})</span>
              </span>

              <div style={localStyles.avatar}>
                <div onClick={() => setShowMenu((prev) => !prev)} style={localStyles.menu}>
                  <Avatar src="/todo-allow-custom-image.jpg" />
                  {state && state.user && <p style={localStyles.username}>{state.user?.username}</p>}
                </div>
                <div ref={ref} style={localStyles.dropdown}>
                  {showMenu && <DropDownMenu />}
                </div>
              </div>

              <Button
                style={{
                  ...localStyles.item,
                  backgroundColor: theme.colors.secondary,
                }}
                variant="contained"
                onClick={() => navigate("/create-post")}
              >
                <PostAddIcon />
              </Button>
            </div>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}
