import { useEffect, useState } from "react";
import { usePostsContext } from "../../../../../context/posts";
import { ModalTypes } from "../../../../../types/common";
import { TransactionStatus } from "../../../../../types/transactions";
import useToggleModal from "../../../../components/hooks/useToggleModal";
import { styles } from "./styles";
import { Post } from "../../../../../types/posts";

interface PendingTransactionsProps {
  pendingTransactions: Post[];
}

export default function PendingTransactions({ pendingTransactions = [] }: PendingTransactionsProps) {
  const { toggle } = useToggleModal();

  return (
    <div>
      {pendingTransactions.length > 0 ? (
        <ul>
          {pendingTransactions.map((transaction) => (
            <li
              key={transaction._id}
              style={{
                ...styles.listItem,
              }}
            >
              <div onClick={() => toggle({ type: ModalTypes.TRANSACTION, value: transaction, isModalOpen: true })}>
                <p>{transaction.title}</p>
              </div>
              <span style={styles.price}>${transaction.transactions.find((t) => t.status === TransactionStatus.PENDING)?.amount}</span>
            </li>
          ))}
        </ul>
      ) : (
        <p style={styles.text}>No pending transactions... </p>
      )}
    </div>
  );
}
