import { CSSProperties } from "react";
import { theme } from "../../../../../theme";

export const styles: { [key: string]: CSSProperties } = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  subtitle: {
    color: theme.colors.secondary,
  },
  address: {
    minWidth: "50%",
    margin: "auto",
    marginBottom: 10,
    padding: 7,
    backgroundColor: theme.colors.grey,
    textAlign: "center",
    boxShadow: theme.shadow.inverse,
    color: theme.colors.white,
  },
  button: {
    minWidth: "12%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "auto",
  },
  warning: {
    color: theme.colors.red,
  },
  warningContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
};
