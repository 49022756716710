import { CSSProperties } from "react";
import { theme } from "../../../theme";

export const styles: { [key: string]: CSSProperties } = {
  container: {
    width: "100%",
    padding: 75,
    backgroundColor: theme.colors.secondary,
  },
  title: {
    fontFamily: "system-ui",
    color: theme.colors.white,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "auto",
  },
};
