import { useState } from "react";
import { login } from "../../../api/users";
import { ACCESS_TOKEN_ACCESS_KEY } from "../../../config";
import { LoginParams, TokenUser } from "../../../types/users";
import { LocalStorage } from "../../../utils/local-storage";
import { useUsersContext } from "../../../context/users";
import jwtDecode from "jwt-decode";
import { UsersTypes } from "../../../context/users/actions";

export const useLoginUser = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { dispatch } = useUsersContext();

  const doLoginUser = async (request: LoginParams, onSuccess: Function, onError: Function) => {
    try {
      if (!request.email.length || !request.password.length) throw new Error("Please Complete All Fields");
      setIsLoading(true);
      const response = await login(request);

      if (response?.access_token) {
        LocalStorage.set(ACCESS_TOKEN_ACCESS_KEY, response.access_token);
        const tokenUser: TokenUser = jwtDecode(response.access_token);
        dispatch({ type: UsersTypes.SET_USER, payload: { user: tokenUser } });
        onSuccess();
      } else throw new Error("Error Logging in");
    } catch (err) {
      setError(err as Error);
      console.error(err);
      onError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const doSignOut = () => LocalStorage.remove(ACCESS_TOKEN_ACCESS_KEY);

  return {
    error,
    isLoading,
    doLoginUser,
    doSignOut,
  };
};
