import React from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import { useUsersContext } from "../../../../../context/users";
import { useNewAddress } from "../../../../Payments/hooks/useNewAddress";
import { styles } from "./styles";
import Block from "../Block";
import { Warning } from "@mui/icons-material";
import { useGetSeed } from "../../../hooks/useGetSeed";

export default function GenerateAddress() {
  const { state } = useUsersContext();
  const { generateAddress, isLoading, address } = useNewAddress();
  const { doGetSeed, result } = useGetSeed();

  return (
    <Block title="Wallet">
      <div style={styles.container}>
        <div>
          <Typography style={styles.subtitle}>Reveal wallet master seed</Typography>
          <div style={styles.warningContainer}>
            <Warning style={{ color: "red" }} />{" "}
            <small style={styles.warning}>Never share your master seed. This can be used to access all your wallets from anywhere</small>
          </div>
          <div style={styles.button}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <div>
                {result && result.seed ? (
                  <div style={styles.address}>{result.seed}</div>
                ) : (
                  <Button variant="contained" onClick={() => doGetSeed()}>
                    Reveal
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
        <div>
          <Typography style={styles.subtitle}>Create new wallet</Typography>
          <div style={styles.warningContainer}>
            <Warning style={{ color: "red" }} />{" "}
            <small style={styles.warning}>
              Transaction fee is charged to transfer funds. It is recommended you withdraw to a private wallet. Do not create a new wallet before the
              old wallets funds are transfered.
            </small>
          </div>{" "}
          <div style={styles.address}>{address || state.user?.wallet}</div>
        </div>
        <div style={styles.button}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button variant="contained" onClick={() => generateAddress()}>
              GENERATE
            </Button>
          )}
        </div>
      </div>
    </Block>
  );
}
