export const styles = {
  container: {
    py: 3,
  },
  paper: {
    p: 3,
  },
  stepper: {
    my: 3,
  },
  contentBox: {
    mt: 3,
    mb: 3,
  },
  navigationBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  flexBox: {
    display: "flex",
    gap: 2,
  },
  imageUploadPaper: {
    p: 2,
    textAlign: "center",
  },
  hiddenInput: {
    display: "none",
  },
  uploadHelperText: {
    mt: 1,
  },
  selectedFilesText: {
    mt: 2,
  },
  characterCount: {
    display: "block",
    textAlign: "right",
    mt: 0.5,
  },
};
